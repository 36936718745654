import React from "react";
import Check from "@/Components/Icons/Check.jsx";
import { Image } from "react-bootstrap";

const items = [
	"Právně platná závěť – nemusíte k notáři",
	"Vytvořeno a ověřeno advokáty",
	"Příprava závěti zabere jen 15 minut",
	"Žádné skryté poplatky",
];

export default function Accredit() {
	return (
		<div>
			{items.map((item, index) => (
				<div key={index}>
					<Check width={14} height={14} fill="#031351" className="me-2" />
					<span className="text-primary small">{item}</span>
				</div>
			))}
			<Image src="/images/accredit-badges.svg" />
		</div>
	);
}
