import React, { useState } from "react";
import { useForm, usePage } from "@inertiajs/react";
import DefaultLayout from "@/Layouts/DefaultLayout.jsx";
import { Alert, Button, Col, Row } from "react-bootstrap";
import FieldGroup from "@/Components/FieldGroup/index.js";
import { useAppContext } from "@/Context/AppContext.jsx";
import { checkKeyDown } from "@/Utils/Form.js";
import FactsCarousel from "@/Components/FactsCarousel/index.js";
import Rating from "@/Components/Rating/index.js";
import Accredit from "@/Components/Accredit/index.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Login() {
	const [emailIsValid, setEmailIsValid] = useState(null);
	const [displayCouponField, setDisplayCouponField] = useState(false);
	const [emailExists, setEmailExists] = useState(null);
	const { flash } = usePage().props;
	const { setCurrentStep } = useAppContext();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { data, setData, errors, post, processing } = useForm();

	setCurrentStep("login");

	const setNewRecaptchaToken = async () => {
		const token = await executeRecaptcha(); //token expires after 2m, doesn't work in submit
		setData((data) => ({ ...data, token: token }));
	};

	const handleEmailChange = async (e) => {
		const isValid = e.target.value.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
		if (isValid) {
			setEmailIsValid(true);

			fetch(window.route("login.email.exists", { email: e.target.value }))
				.then((response) => response.json())
				.then((data) => {
					setEmailExists(data?.exists);
				})
				.catch(() => setEmailExists(null));

			setData((data) => ({ ...data, email: e.target.value }));
			setNewRecaptchaToken(); //in submit doesn't work on 1. request
		} else {
			setEmailIsValid(false);
			setEmailExists(null);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		await setNewRecaptchaToken();
		post(window.route("login.authenticate"), { preserveScroll: true });
	};

	return (
		<DefaultLayout>
			<h1 className="text-md-start text-primary fw-bold mt-1">
				Vytvořte si závěť z pohodlí domova
			</h1>
			<p className="text-md-start fs-5 text-primary mb-5">
				Vyplňte e-mailovou adresu a začněte připravovat svoji závěť. Pokud se
				chcete vrátit k již <br className="d-none d-md-inline" />
				rozpracované závěti, zadejte stejnou e-mailovou adresu, kterou jste
				zadali při prvním přihlášení.
			</p>

			<Row>
				<Col
					md={6}
					className="order-1 order-md-0 py-6 px-4 px-lg-8 bg-light-beige separator outer-rounded"
				>
					<FactsCarousel className={"mb-4 mt-2"} />
					<div>
						<div className="ratio ratio-16x9">
							<iframe
								src="https://www.youtube.com/embed/0wwyxetRT2A?si=evnwKb3_lU58R8Aq"
								title="Proč je závěť důležitá?"
								allowFullScreen
								className="embed-responsive-item"
							></iframe>
						</div>
					</div>
					<Rating />
				</Col>

				<Col
					md={6}
					className="d-flex flex-column justify-content-center order-0 order-md-1 py-6 px-4 px-lg-8 bg-light-beige outer-rounded"
				>
					<form
						className="mb-4"
						onSubmit={handleSubmit}
						onKeyDown={(e) => checkKeyDown(e)}
					>
						<Row>
							<h2 className="text-primary fw-bold">Zadejte váš e-mail</h2>
							<FieldGroup
								// label="E-mailová adresa"
								name="email"
								type="email"
								placeholder={"E-mail"}
								defaultValue={data?.email}
								autoFocus={true}
								// required={true}
								isInvalid={emailIsValid === false || !!errors?.email}
								error={
									(!emailIsValid
										? "Zadejte platnou e-mailovou adresu"
										: null) || errors?.email
								}
								className={"position-relative"}
								fieldClassName={"p-3 rounded-4"}
								onChange={handleEmailChange}
							/>
							{!displayCouponField && emailExists === false && (
								<a
									href="#"
									className="mt-3"
									onClick={(e) => {
										e.preventDefault();
										setDisplayCouponField(true);
									}}
								>
									Máte slevový kód?
								</a>
							)}
							{displayCouponField && (
								<FieldGroup
									label="Slevový kód"
									name="coupon"
									defaultValue={data?.coupon}
									isInvalid={!!errors?.coupon}
									error={errors?.coupon}
									className={"position-relative mt-4"}
									fieldClassName={"p-3 rounded-4"}
									onChange={(e) => setData("coupon", e.target.value)}
								/>
							)}
						</Row>

						<div>
							{flash?.success ? (
								<Alert className="mt-5" variant="success">
									{flash?.success}
								</Alert>
							) : null}
							{flash?.error ? (
								<Alert className="mt-5" variant="danger">
									{flash?.error}
								</Alert>
							) : null}

							<div className="d-flex align-items-center justify-content-center justify-content-md-end mt-4">
								<Button
									variant="primary"
									size="lg"
									type="submit"
									style={{ padding: 13 }}
									className="flex-fill flex-md-grow-0"
									disabled={!emailIsValid || processing}
								>
									{emailExists ? "Přihlásit se" : "Vytvořit závěť"}
								</Button>
							</div>
						</div>
					</form>
					<Accredit />
				</Col>
			</Row>
		</DefaultLayout>
	);
}
